/**
 *		Force an element to self-clear its floated children
 *
 *		element/.class/#id {
 *			@extend %clearfix;
 *		}
 */
%clearfix {
	&::after {
		content: '';
		display: block;
		clear: both;
	}
}

/**
 *		Absolutely position an element to the full width/height of its parent
 *
 *		element/.class/#id {
 *			@extend %fullcoverage;
 *		}
 */
%fullcoverage {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

/**
 *		Absolutely position an element to the center of its parent
 *
 *		element/.class/#id {
 *			@extend %center;
 *		}
 */
%center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}