.appbar {
	$breakpoint_appbar_desktop : 42em;
	color: white;
	background-color: $color_primary;

	.wrapper {
		//max-width: $max_width_content;
		margin-right: auto;
		margin-left: auto;

		@media (min-width: $breakpoint_appbar_desktop) {
			display: flex;
			align-items: center;
		}

		.title {
			padding: 1rem $padding_content;
			font-weight: 700;
			font-size: 1.25rem;

			@media (min-width: $breakpoint_appbar_desktop) {
				flex: 0 0 auto;
				font-size: 1.375rem;
			}
		}

		.tab-group {
			display: inline-flex;
			width: 100%;
			padding: 0 $padding_content;

			@media (min-width: $breakpoint_appbar_desktop) {
				flex: 1 1 100%;
				padding-top: 1rem;
			}

			a {
				display: inline-flex;
				align-items: center;
				padding-top: .75rem;
				@include scale(padding-right, .875rem, 20em, 1.1875rem, $breakpoint_appbar_desktop);
				padding-bottom: .75rem;
				@include scale(padding-left, .875rem, 20em, 1.1875rem, $breakpoint_appbar_desktop);
				color: white;
				font-weight: 700;
				font-size: 1rem;
				text-decoration: none;
				border: 1px solid $color_borders;
				border-bottom: 0;
				border-radius: 5px 5px 0 0;

				svg {
					&:first-child {
						margin-right: .5625rem;
					}

					&:last-child {
						margin-left: .5625rem;
					}

					path {
						fill: currentColor;
					}
				}

				&.standard {
					margin-right: .5625rem;
				}

				&.quote-cart {
					margin-left: auto;

					span {
						display: none;
					}

					svg {
						margin: 0;
					}

					@media (min-width: $breakpoint_appbar_desktop) {
						span {
							display: block;
						}

						svg {
							margin-left: .5625rem;
						}
					}
				}

				&.active,
				&:hover,
				&:focus {
					color: $color_primary;
					background-color: white;
					border-color: white;
				}
			}
		}
	}
}