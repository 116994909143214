@import 'appbar';
@import 'browser-update';
@import 'forms';
@import 'tooltip';

.container {
	$breakpoint_main_flex : 60em;

	@media (min-width: $breakpoint_main_flex) {
		display: flex;
	}

	@import 'aside';
	@import 'main';
}