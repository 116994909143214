main {
	padding: 1rem 0;

	@media (min-width: $breakpoint_main_flex) {
		flex: 1 1 auto;
	}

	.product-results {
		> header {
			margin-bottom: 1.5rem;
			padding: 0 $padding_content;
			color: #030303;
			font-weight: 700;
			font-size: 1.5rem;

			@media (min-width: 40em) {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}

			@media (min-width: $breakpoint_main_flex) {
				margin-top: 1.5rem;
				margin-bottom: .75rem;
				color: #828282;
				font-weight: 400;
				font-size: .8125rem;
			}

			form {
				display: flex;
				margin-top: 2rem;

				@media (min-width: 40em) {
					margin: 0;
				}

				input {
					flex: 1 1 100%;
					min-width: 240px;
					height: 40px;
					padding: 1rem 40px 1rem 1rem;
					border: 1px solid #979797;
					border-right: 0;
					border-radius: 0;
				}

				.clear {
					position: relative;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 40px;
					height: 40px;
					margin-left: -40px;
				}

				button {
					flex: 1 1 40px;
					min-width: 40px;
					max-width: 40px;
					height: 40px;
					padding: 0;
					background-color: $color_primary;
					border: 0;

					span {
						display: flex;
						justify-content: center;
						align-items: center;

						svg {
							display: block;
							width: 20px;
							height: 20px;

							path {
								fill: white;
							}
						}
					}
				}
			}
		}

		.results {
			border-collapse: collapse;
			width: 100%;
			color: #1d1d1d;

			thead {
				tr {
					th {
						position: relative;
						padding: .3125rem .75rem;
						font-weight: 700;
						font-size: .8125rem;
						text-align: left;

						&:nth-of-type(4),
						&:nth-of-type(5),
						&:nth-of-type(6),
						&:nth-of-type(7) {
							display: none;
						}

						@media (min-width: 26em) {
							&:nth-of-type(4) {
								display: table-cell;
							}
						}

						@media (min-width: 32em) {
							&:nth-of-type(5) {
								display: table-cell;
							}
						}

						@media (min-width: 40em) {
							&:nth-of-type(6) {
								display: table-cell;
							}
						}

						@media (min-width: 45em) {
							&:nth-of-type(7) {
								display: table-cell;
							}
						}
					}
				}
			}

			tbody {
				tr {
					td {
						padding: .3125rem .75rem;
						font-size: .75rem;
						border-top: 1px solid #eaeaea;

						&:nth-of-type(4),
						&:nth-of-type(5),
						&:nth-of-type(6),
						&:nth-of-type(7) {
							display: none;
						}

						@media (min-width: 26em) {
							&:nth-of-type(4) {
								display: table-cell;
							}
						}

						@media (min-width: 32em) {
							&:nth-of-type(5) {
								display: table-cell;
							}
						}

						@media (min-width: 40em) {
							&:nth-of-type(6) {
								display: table-cell;
							}
						}

						@media (min-width: 45em) {
							&:nth-of-type(7) {
								display: table-cell;
							}
						}
					}

					&.selected {
						color: white;
						background-color: $color_primary;
					}
				}
			}
		}
	}

	.quote-cart {
		max-width: 62rem;
		margin: 0 auto;
		padding: $padding_content;

		> header {
			margin-bottom: 2rem;
			color: #030303;
			font-weight: 700;
			font-size: 2.1875rem;
		}

		.quote-item {
			display: flex;
			padding: .75rem 1rem;
			border: 1px solid black;
			box-shadow: 0 20px 0 -10px #dfdfdf;

			.img {
				flex: 0 0 auto;
				margin-right: 1rem;
				padding-top: .75rem;
			}

			.details {
				flex: 1 1 auto;

				.row {
					display: flex;
					padding: .75rem 0;

					&.name-quantity {
						justify-content: space-between;

						.name {
							font-weight: 700;
							font-size: 1.5rem;
						}

						.quantity {
							display: flex;
							align-items: center;

							.label {
								margin-right: .5rem;
							}

							.increment,
							.decrement {
								display: inline-flex;
								justify-content: center;
								align-items: center;
								width: 2rem;
								height: 2rem;
								padding: .5rem;
								font-weight: 600;
								font-size: .75rem;
								border: 2px solid black;
								cursor: pointer;
							}

							.decrement {
								border-right: 0;
							}

							label {
								input {
									width: 3rem;
									height: 2rem;
									font-weight: 600;
									font-size: 1.25rem;
									text-align: center;
									border-color: black;
								}
							}

							.increment {
								border-left: 0;
							}

							.remove {
								margin-left: .5rem;
							}
						}
					}

					&:not(.name-quantity) {
						border-top: 1px solid #dbdbdb;
					}

					strong {
						width: 7rem;
						margin-right: 1rem;
					}
				}
			}

			+ .quote-item {
				margin-top: 2rem;
			}

			+ header {
				margin-top: 5rem;
			}
		}

		.quote-form {
			padding: 1.75rem 1rem;
			border: 1px solid black;
			box-shadow: 0 20px 0 -10px #dfdfdf;

			label {
				display: block;
				width: 100%;

				+ label {
					margin-top: 1.5rem;
				}

				.field-label {
					display: block;
					margin-bottom: .25rem;

					&.required::after {
						content: '*';
						display: inline-block;
						margin-left: .125rem;
						color: #c7031e;
					}
				}

				input {
					width: 100%;
					padding: .6875rem .875rem;
					font-family: inherit;
					line-height: 1.5;

					&[type=file] {
						border: 1px dashed;
					}
				}

				textarea {
					width: 100%;
					height: 10rem;
				}

				&.select {
					&::after {
						top: auto;
						right: .875rem;
						bottom: 1.3125rem;
						transform: translateY(50%);
					}

					select {
						width: 100%;
						height: 2.625rem;
						padding-right: 1.75rem;
						padding-left: .875rem;
						font-size: 1rem;
						border: 1px solid rgb(238, 238, 238);
						border-radius: 0;
					}
				}

				.field-error {
					display: block;
					padding-top: .25rem;
					color: #c7031e;
					font-style: italic;
					font-size: .875rem;

					&:empty {
						display: none;
					}
				}
			}
		}
	}
}