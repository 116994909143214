.radio-list {
	label {
		display: flex;
		border: 1px solid #eee;
	}
}

.radio-two {
	display: flex;

	label {
		flex: 1 1 auto;
		position: relative;

		input {
			position: absolute;
			opacity: 0;
			z-index: -1;
		}

		span {
			display: block;
			width: 100%;
			padding: .75rem;
			text-align: center;
			border: 1px solid #030303;
		}

		input:checked {
			+ span {
				color: white;
				background-color: $color_secondary;
				border-color: $color_secondary;
			}
		}
	}

	&.with-select {
		.radio {
			flex: 0 0 auto;
		}

		.select {
			flex: 1 1 auto;
			display: flex;
			align-items: center;
			padding: 0 .5rem;
			border: 1px solid #030303;

			&::after {
				right: .5rem;
			}

			select {
				flex: 1 1 100%;
				color: #1d1d1d;
				font-size: .9375rem;
			}
		}
	}
}

.select {
	position: relative;

	&::after {
		content: url(../svgs/select-expand.svg);
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 1;
		transform: translateY(-50%);
	}

	select {
		appearance: none;
		position: relative;
		padding-right: .75rem;
		background-color: transparent;
		border: 0;
		z-index: 2;

		&::-ms-expand {
			display: none;
		}
	}
}

.buttons {
	display: flex;
	margin-top: 2rem;

	&.center {
		justify-content: center;
	}

	button {
		display: flex;
		align-items: center;
		padding: .5rem .75rem;
		color: white;
		font-weight: 700;
		font-size: .9375rem;
		text-decoration: none;
		white-space: nowrap;
		background-color: $color_secondary;
		border: 0;

		svg {
			margin-right: .5rem;
		}

		&:hover,
		&:focus {
			background-color: $color_primary;
		}
	}
}