/**
 *		Duplicate contained style rules across various media breakpoints AND classes
 *
 *		Replace "@media (min-width: $breakpoint)" with "@include accessibility($breakpoint)"
 *		and this will automatically multiply the breakpoint by 1.5 and 2 and duplicate any
 *		@content within inside new media queries tied to data-font-size attribute values
 *		on the `html` element
 */
@mixin accessibility($breakpoint) {
	@media (min-width: $breakpoint) {
		@at-root html[data-font-size='nm'] & {
			@content;
		}
	}

	@media (min-width: #{$breakpoint * 1.5}) {
		@at-root html[data-font-size='lg'] & {
			@content;
		}
	}

	@media (min-width: #{$breakpoint * 2}) {
		@at-root html[data-font-size='xl'] & {
			@content;
		}
	}
}

/**
 *		Scale units gracefully on any property that supports calc()
 *
 *		element/.class/#id {
 *			// Scales font-size from 16px (at 320px) to 24px (at 640px)
 *			@include scale(font-size, 1rem, 20rem, 1.5rem, 40rem);
 *		}
 */
@mixin scale($prop, $start-size, $start-width, $end-size, $end-width) {
	#{$prop}: #{$start-size};
	@media (min-width: #{$start-width}) {
		#{$prop}: calc(#{$start-size} + #{strip-unit($end-size - $start-size)} * (100vw - #{$start-width}) / #{strip-unit($end-width - $start-width)});
	}
	@media (min-width: #{$end-width}) {
		#{$prop}: #{$end-size};
	}
}

/**
 *		Generate column-based layouts using flexbox
 *
 *		@include on the parent element and all direct children (of any type)
 *		will form a column layout at the specified breakpoints
 *
 *		element/.class/#id {
 *			// Forms a 2-column layout at 480px, 3-columns at 640px,
 *			// jumps back to 2 columns at 800px, etc.
 *			@include columns($margin_columns, $margin_rows, (
 *				30em : 2,
 *				40em : 3,
 *				50em : 2,
 *				60em : 3,
 *				70em : 4
 *			));
 *		}
 */
@mixin columns($margin_columns, $margin_rows, $breakpoint_map) {
	display: flex;
	flex-wrap: wrap;
	@if $margin_columns == 0 {
		margin-right: 0;
		margin-left: 0;
	} @else {
		margin-right: -#{$margin_columns / 2};
		margin-left: -#{$margin_columns / 2};
	}

	> * {
		display: block;
		@if $margin_columns == 0 {
			width: 100%;
			margin-right: 0;
			margin-left: 0;
		} @else {
			width: calc(100% - #{$margin_columns});
			margin-right: #{$margin_columns / 2};
			margin-left: #{$margin_columns / 2};
		}
		margin-bottom: $margin_rows;

		@each $breakpoint, $num_columns in $breakpoint_map {
			@media (min-width: $breakpoint) {
				flex: 1 1 auto;
				@if $margin_columns == 0 {
					width: calc(100% / #{$num_columns}.0001);
					max-width: calc(100% / #{$num_columns}.0001);
				} @else {
					width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
					max-width: calc((100% - #{$margin_columns * $num_columns}) / #{$num_columns}.0001);
				}
			}
		}
	}

	@supports (display: grid) {
		display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: $margin_columns;
		grid-row-gap: $margin_rows;
		margin-right: 0;
		margin-bottom: $margin_rows;
		margin-left: 0;

		@each $breakpoint, $num_columns in $breakpoint_map {
			$val: 1fr;
			@if $num_columns >= 2 {
				// Loop from 2 through $num_columns since $val already has a value of '1fr'
				@for $i from 2 through $num_columns {
					$val: append($val, 1fr, space);
				}
				@media (min-width: $breakpoint) {
					grid-template-columns: $val;
				}
			} @else {
				@media (min-width: $breakpoint) {
					grid-template-columns: $val;
				}
			}
		}

		> * {
			min-width: 0;
			width: auto;
			max-width: none;
			margin-right: 0;
			margin-bottom: 0;
			margin-left: 0;
		}
	}
}

/**
 *		scrim-gradient
 *
 *		- Customizes the color stops for a much smoother transition
 *		- ONLY works from a single color to transparent
 *		- See https://css-tricks.com/easing-linear-gradients/ for details
 *
 *		element/.class/#id {
 *			@include scrim-gradient($direction, $color);
 *		}
 */
@mixin scrim-gradient($direction, $color) {
	background-image: linear-gradient($direction,
		$color 0%,
		rgba($color, .738) 19%,
		rgba($color, .541) 34%,
		rgba($color, .382) 47%,
		rgba($color, .278) 56.5%,
		rgba($color, .194) 65%,
		rgba($color, .126) 73%,
		rgba($color, .075) 80.2%,
		rgba($color, .042) 86.1%,
		rgba($color, .021) 91%,
		rgba($color, .008) 95.2%,
		rgba($color, .002) 98.2%,
		rgba($color, 0) 100%);
}

/**
 * 		(WIP) Generate a realistic box shadow using ambient and drop shadows.
 *
 *		@include physical-shadow(11); // uses default shadow color
 *		@include physical-shadow(11, darkgray); //specify a different shadow color
 *		@include physical-shadow(11, $transform: true); // translates the element up an appropriate distance, useful for transitions.
 */
@mixin physical-shadow (
	$altitude, // an abstract distance from the page surface. 0 is flush with the surface
	$shadow-color: black,
	$transform: false
) {
	$distance: $altitude * 1px;

	box-shadow:
		// ambient shadow
		0 $distance ($altitude * 5.00px) ($altitude * -0.25px) rgba($shadow-color, 0.08 - ($altitude * 0.002)),
		// drop shadow
		0 $distance ($altitude * 1.00px) ($altitude * -0.25px) rgba($shadow-color, 0.20 - ($altitude * 0.003));

	@if $transform {
		transform: translate3d(0, $distance * 0.5, 0px);
	}
}

/**
 *		Center block level elements using auto margins.
 *
 *		- Padding keeps space on sides in mobile layout
 *
 *		@include contained; // uses default settings
 *		@include contained(110ch); // uses default padding
 *		@include contained(110ch, 2rem);
 */
@mixin contained (
	$contained-width: 76rem,
	$padding-x: 3vw
) {
	margin-left: auto;
	margin-right: auto;
	padding-left: $padding;
	padding-right: $padding;
	max-width: calc(#{$contained-width} + #{$padding * 2});
}

/**
 *		Browser hack wrapper
 *
 *		- supports ie11, edge
 *
 *		@include browser(ie11, edge) {
 * 			// ie11 and edge browser-specific rules
 * 		}
 */
@mixin _browser-ie11 { @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { @content; } }
@mixin _browser-edge { @supports (-ms-ime-align: auto) { @content; } }
@mixin browser ($queries...) {
	@each $q in $queries {
		     @if $q == 'ie11' { @include _browser-ie11 { @content; } }
		@else if $q == 'edge' { @include _browser-edge { @content; } }
	}
}

/**
 * 		Visually hide an element without masking it from crawlers / assistive technology
 */
@mixin visuallyhidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding:0 !important;
	border:0 !important;
	height: 1px !important;
	width: 1px !important;
	overflow: hidden;
}

/**
 *		Force an element to self-clear its floated children
 *
 *		@include clearfix; // uses ::after
 *		@include clearfix(before); // uses ::before instead
 */
@mixin clearfix (
	$pseudo: "after"
) {
	&::#{$pseudo} {
		content: "";
		display: table;
		clear: both;
	}
}

/**
 *		Absolutely position an element to the full width/height of its parent
 *
 *		@include fullcoverage;
 */
@mixin fullcoverage () {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

/**
 *		Absolutely position an element to the center of its parent
 *
 *		@include center;
 */
@mixin center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}