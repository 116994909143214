aside {
	display: none;
	margin-bottom: 2rem;
	padding: 0;

	&.active {
		display: block;
	}

	@media (min-width: $breakpoint_main_flex) {
		flex: 1 1 20rem;
		min-width: 20rem;
		max-width: 20rem;
		margin-bottom: 0;
		padding: 0;
		background-color: #ededed;
		border-right: 1px solid #030303;
	}

	&.advanced {
		.filter-type {
			display: flex;

			a {
				flex: 1 1 auto;
				padding: 1.5rem .5rem;
				color: #030303;
				font-weight: 700;
				font-size: .875rem;
				text-align: center;
				text-decoration: none;
				background-color: white;
				border-bottom: 1px solid #030303;

				&.active,
				&:hover,
				&:focus {
					color: white;
					background-color: $color_secondary;
					border-color: $color_secondary;
				}
			}
		}

		.back-to-types {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: 1rem $padding_content 0;
			color: #2f2f2f;
			font-weight: 700;
			font-size: .875rem;
			text-decoration: none;

			@media (min-width: $breakpoint_main_flex) {
				display: none;
			}

			svg {
				margin-right: .75rem;
			}
		}

		> header {
			margin-bottom: 1.5rem;
			padding: 1rem $padding_content 0;

			@media (min-width: $breakpoint_main_flex) {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				padding: 1rem $padding_content;
				background-color: white;
				border-bottom: 1px solid #030303;
			}

			.img {
				display: none;

				@media (min-width: $breakpoint_main_flex) {
					flex: 1 1 3.125rem;
					display: block;
					min-width: 3.125rem;
					max-width: 3.125rem;
					margin-right: 1.125rem;
				}
			}

			.copy {
				.label {
					display: none;

					@media (min-width: $breakpoint_main_flex) {
						display: block;
						color: #1d1d1d;
						font-size: .9375rem;
					}
				}

				.type {
					color: #030303;
					font-weight: 700;
					font-size: 1.5rem;

					@media (min-width: $breakpoint_main_flex) {
						font-size: 1.125rem;
					}
				}
			}
		}

		.product-filter {
			margin-right: $padding_content;
			margin-left: $padding_content;
			border: 1px solid #979797;
			border-bottom: 0;

			@media (min-width: $breakpoint_main_flex) {
				margin-right: 0;
				margin-left: 0;
				border: 0;
				border-bottom: 1px solid #030303;
			}

			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.label {
					flex: 1 1 auto;
					padding: .75rem 1rem;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;

					strong::after {
						content: ':';
					}
				}

				.svg {
					padding: .75rem;
					text-align: center;

					svg {
						display: inline-block;
					}
				}
			}

			.bottom {
				display: none;
				padding: 0 1rem .75rem 1rem;

				.option-wrapper {
					display: flex;
					align-items: center;

					.options {
						flex: 1 1 auto;

						.option {
							display: flex;
							padding: .375rem 0;

							.label {
								flex: 0 0 3rem;
								min-width: 3rem;
								max-width: 3rem;
								margin-right: 1rem;
								color: #1d1d1d;
								font-size: .9375rem;
							}

							select {
								@include scale(min-width, 7rem, 20em, 40rem, $breakpoint_to_desktop_nav);
								@include scale(max-width, 7rem, 20em, 40rem, $breakpoint_to_desktop_nav);
								padding-bottom: .125rem;
								color: #030303;
								font-weight: 700;
								font-size: .9375rem;
								border-bottom: 1px solid #ccc;
								border-radius: 0;
								cursor: pointer;

								@media (min-width: $breakpoint_to_desktop_nav) {
									min-width: 10rem;
									max-width: 10rem;
								}
							}
						}
					}

					.img {
						flex: 0 0 2.75rem;
						min-width: 2.75rem;
						max-width: 2.75rem;
						margin-left: 1rem;
					}
				}
			}

			&.expanded {
				background-color: white;

				.top {
					.label {
						strong::after {
							display: none;
						}

						span {
							display: none;
						}
					}

					.svg {
						svg {
							transform: rotate(45deg);

							path {
								fill: #8c8c8c;
							}
						}
					}
				}

				.bottom {
					display: block;
				}
			}
		}
	}

	.toggle-product-filters {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-right: $padding_content;
		margin-left: $padding_content;
		padding: .75rem .75rem .75rem 1rem;
		color: #5d5d5d;
		font-weight: 700;
		font-size: .875rem;
		text-transform: uppercase;
		background-color: #f0f0f0;
		border: 1px solid #979797;

		@media (min-width: $breakpoint_main_flex) {
			display: none;
		}

		.svg {
			width: 1rem;
			margin-left: 1rem;
			text-align: center;

			svg {
				display: inline-block;
			}
		}
	}

	&.basic {
		.filter {
			padding: .75rem 1rem;

			> header {
				display: flex;

				strong {
					margin-right: 1rem;
					color: $color_primary;
					font-weight: 700;
					font-size: 2rem;
				}

				span {
					padding-top: .5rem;
					color: #030303;
					font-weight: 700;
				}
			}
		}
	}

	&.product-detail {
		background-color: white;

		.overview {
			display: flex;
			color: white;
			background-color: $color_secondary;
			border-bottom: 1px solid #030303;

			.info {
				flex: 1 1 auto;
				padding: 1.25rem .75rem;

				strong,
				span {
					display: block;
				}

				strong {
					font-weight: 700;
					font-size: 1.375rem;
				}

				span {
					font-size: .9375rem;
				}
			}

			.close {
				flex: 0 0 auto;
				padding: 1.25rem .75rem;

				svg {
					display: block;
				}
			}
		}

		.imgs {
			display: flex;
			padding: 1rem;

			.main {
				flex: 1 1 auto;
				text-align: center;

				img {
					display: inline-block;
					max-width: 100%;
				}
			}

			.thumbs {
				flex: 0 1 auto;

				.img {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2.625rem;
					height: 2.625rem;
					border: 1px solid #979797;

					img {
						max-width: 100%;
						max-height: 100%;
					}

					+ .img {
						margin-top: .5rem;
					}
				}
			}
		}

		.product-details {
			margin-right: $padding_content;
			margin-left: $padding_content;
			border: 1px solid #979797;
			//border-bottom: 0;

			@media (min-width: $breakpoint_main_flex) {
				margin-right: 0;
				margin-left: 0;
				border: 0;
				border-bottom: 1px solid #030303;
			}

			.top {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.label {
					flex: 1 1 auto;
					padding: .75rem 1rem;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;

					strong::after {
						content: ':';
					}
				}

				.svg {
					padding: .75rem;
					text-align: center;

					svg {
						display: inline-block;
					}
				}
			}

			.bottom {
				display: none;
				padding: 0 1rem .75rem 1rem;

				.option-wrapper {
					display: flex;
					align-items: center;

					.options {
						flex: 1 1 auto;

						.option {
							display: flex;
							padding: .375rem 0;

							.label {
								flex: 0 0 3rem;
								min-width: 3rem;
								max-width: 3rem;
								margin-right: 1rem;
								color: #1d1d1d;
								font-size: .9375rem;
							}

							select {
								@include scale(min-width, 7rem, 20em, 40rem, $breakpoint_to_desktop_nav);
								@include scale(max-width, 7rem, 20em, 40rem, $breakpoint_to_desktop_nav);
								padding-bottom: .125rem;
								color: #030303;
								font-weight: 700;
								font-size: .9375rem;
								border-bottom: 1px solid #ccc;
								border-radius: 0;
								cursor: pointer;

								@media (min-width: $breakpoint_to_desktop_nav) {
									min-width: 10rem;
									max-width: 10rem;
								}
							}
						}
					}

					.img {
						flex: 0 0 2.75rem;
						min-width: 2.75rem;
						max-width: 2.75rem;
						margin-left: 1rem;
					}
				}
			}

			&.expanded {
				background-color: white;

				.top {
					.label {
						strong::after {
							display: none;
						}

						span {
							display: none;
						}
					}

					.svg {
						svg {
							transform: rotate(45deg);

							path {
								fill: #8c8c8c;
							}
						}
					}
				}

				.bottom {
					display: block;
				}
			}

			&.measurements {
				header {
					padding: .75rem 1rem;
					font-weight: 600;
				}

				table {
					width: 100%;

					tr {
						td {
							padding: .5rem;
							border-bottom: 1px solid #eaeaea;

							&:first-of-type {
								padding-left: 1rem;
							}

							&:last-of-type {
								padding-right: 1rem;
							}
						}

						&:last-of-type td {
							border-bottom: 0;
						}
					}
				}
			}
		}

		.add-to-cart {
			display: flex;
			margin-top: 1rem;
			border-top: 1px solid #030303;
			border-bottom: 1px solid #030303;

			@media (min-width: $breakpoint_main_flex) {
				margin-top: 0;
				border-top: 0;
			}

			.decrement,
			.increment {
				flex: 0 0 auto;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: .5rem .75rem;
				border-right: 1px solid #030303;
				cursor: pointer;

				svg {
					display: block;

					path {
						fill: #1d1d1d;
					}
				}
			}

			label {
				flex: 1 1 auto;
				display: flex;
				border-right: 1px solid #030303;

				input {
					width: 100%;
					padding: .5rem .75rem;
					font-weight: 700;
					font-size: 1.125rem;
					border: 0;
				}
			}

			.rd-button {
				display: flex;
				align-items: center;
				padding: .5rem .75rem;
				color: white;
				font-weight: 700;
				font-size: .9375rem;
				text-decoration: none;
				white-space: nowrap;
				background-color: $color_secondary;

				svg {
					margin-right: .5rem;
				}

				&:hover,
				&:focus {
					background-color: $color_primary;
				}
			}
		}
	}
}