#rpf {
	box-sizing: border-box;
	font-family: Arial, sans-serif;

	*, *::before, *::after {
		box-sizing: inherit;
	}

	@import 'base/index';
	@import 'components/index';
}