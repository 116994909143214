.tooltip {
	position: absolute;
	width: 14rem;
	background-color: white;
	border: 2px solid $color_primary;
	border-radius: 6px;
	box-shadow: 0 4px 8px rgba(black, .2);

	img {
		max-width: 100%;
	}

	p {
		margin: 1em 0;
		padding: 0 1em;
		font-weight: 400;
	}

	&::after {
		content: '';
		position: absolute;
		width: 0;
		height: 0;
	}

	&.marker-top {
		top: 100%;
		left: 50%;
		transform: translateX(-50%);

		&::after {
			left: 50%;
			bottom: 100%;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $color_primary;
			border-left: 8px solid transparent;
			transform: translateX(-50%);
		}
	}

	&.marker-right {
		top: 50%;
		right: 100%;
		transform: translateY(-50%);

		&::after {
			left: 100%;
			top: 50%;
			border-top: 8px solid transparent;
			border-left: 8px solid $color_primary;
			border-bottom: 8px solid transparent;
			transform: translateY(-50%);
		}
	}

	&.marker-bottom {
		bottom: 100%;
		left: 50%;
		transform: translateX(-50%);

		&::after {
			top: 100%;
			left: 50%;
			border-top: 8px solid $color_primary;
			border-right: 8px solid transparent;
			border-left: 8px solid transparent;
			transform: translateX(-50%);
		}
	}

	&.marker-left {
		top: 50%;
		left: 100%;
		transform: translateY(-50%);

		&::after {
			top: 50%;
			right: 100%;
			border-top: 8px solid transparent;
			border-right: 8px solid $color_primary;
			border-bottom: 8px solid transparent;
			transform: translateY(-50%);
		}
	}
}