/**
 *		Project (style guide)
 */
$color_primary								: #005091;
$color_secondary							: #348fd8;
$color_borders								: #2479bf;

$max_width_content							: 64.5rem;
$padding_content							: 1rem;

/**
 *		Breakpoints
 */
$breakpoint_to_sidebar						: 50em;
$breakpoint_to_desktop_nav					: 60em;