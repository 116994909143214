#browser-update {
	background-color: rgba(#c7031e, .1);
	border-bottom: 1px solid rgba(#c7031e, .4);
	color: #c7031e;
	margin: 0;
	padding: 0;

	p {
		margin: 0 auto;
		padding: 1em;
		width: 90%;
	}

	a {
		color: #c7031e;
	}
}